import React from 'react'
import {
  SolutionTopBanner,
  ProductSupport,
  ProductBlog,
} from '../../components/block'
import AnimationWrap from '../../components/block/AnimationWrap'
import { ProductNav } from '../../components/header/Header'
import SEO from '../../components/SEO'
import {
  Feedback,
  MapContainer,
  Solution as PartSolution,
} from './bare-metal-cloud'
import { graphql } from 'gatsby'
import Footer from '../../components/footer'
import { Button } from '../../components/ui'
import Drop from '../../components/drop'
import '../../styles/edcs.less'
const loadText = require('src/utils').loadText
const url_data = loadText('url')
const static_data = loadText('edcs')
const staticPath = '/products/edcs'
const BottomBanner = ({ title, description, buttonText }) => (
  <div className="edcs-bottomBanner">
    <div className="edcs-bottomBanner-content-container full-bg">
      <div className="edcs-bottomBanner-content page-middle">
        <AnimationWrap>
          <div className="font-36 semi-bold">{title}</div>
          <div
            className="font-20 mt-24 description"
            style={{ maxWidth: '858px' }}
          >
            {description}
          </div>
          <div className="mt-32 btn-wrapper">
            <Button
              className="white"
              width={290}
              size="l"
              onClick={() => {
                window.open(url_data.rhs.link)
              }}
            >
              {buttonText}
            </Button>
          </div>
        </AnimationWrap>
      </div>
    </div>
  </div>
)
export const Action = ({
  pic,
  title,
  description,
  buttonText,
  onClick,
  picM = '',
}) => (
  <div className="edcs-action">
    <AnimationWrap>
      <div className="edcs-action-content block-middle">
        {/* <div className="edcs-action-imgContainer"> */}
        <div className="imgContainer">
          <picture>
            <source
              srcSet={`${picM || pic} 640w `}
              media="(max-width: 640px)"
            />
            <img src={pic} alt={title} />
          </picture>
        </div>
        {/* </div> */}
        <div className="right  mobile-paddin">
          <div className="font-20">{title}</div>
          <div
            className="font-28 semi-bold t-color mt-24 description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
          <div className="mt-32 btn-wrapper">
            <Button width={392} onClick={onClick} size="l">
              {/* {static_data.action.buttonText} */}
              {buttonText}
            </Button>
          </div>
        </div>
      </div>
    </AnimationWrap>
  </div>
)
const Feature = () => (
  <div className="edcs-feature">
    <AnimationWrap>
      <div className="font-36 semi-bold t-color mobile-padding">
        {static_data.feature.title}
      </div>
      <div className="edcs-feature-content">
        {static_data.feature.content.map(({ title, description }, idx) => (
          <div className="edcs-feature-item">
            <img src={`${staticPath}/feature-${idx + 1}.svg`} alt="" />
            <div className="font-20 semi-bold t-color mt-32 title">{title}</div>
            <div className="mt-24 description" style={{ lineHeight: '24px' }}>
              {description}
            </div>
          </div>
        ))}
      </div>
    </AnimationWrap>
  </div>
)
const Partnership = () => (
  <div className="edcs-partnerships">
    <Drop color="rgb(245,249,255)" />
    <div className="edcs-partnerships-content full-bg">
      <AnimationWrap>
        <div className="font-36 semi-bold mobile-padding">
          {static_data.partnerships.title}
        </div>
        <div
          className="font-20 mt-24 block-middle mobile-padding description"
          style={{ maxWidth: '858px' }}
        >
          {static_data.partnerships.description}
        </div>
        <div className="edcs-partnerships-iconContainer">
          {[
            'CoreSite',
            'Cyxtera',
            'Digital Reality',
            'EdgeConneX',
            'Equinix',
            'PLDT',
          ].map((key, idx) => (
            <div className="edcs-partnerships-icon">
              <img src={`/products/edcs/partner-${idx + 1}.svg`} alt={key} />
              <div className="font-16 name">{key}</div>
            </div>
          ))}
        </div>
      </AnimationWrap>
    </div>
  </div>
)
const LeftRightLayout = ({
  static_data,
  className = '',
}: {
  static_data: any
  className?: string
}) => (
  <div className={`left-right-layout ${className}`}>
    {static_data.map(({ title, description, src }, idx) => (
      <div className="left-right-layout-item mt-56  block-middle">
        <div className={`imgContainer mobile-padding`}>
          <picture>
            <source
              srcSet={`${src.split('.')[0]}-m.png`}
              media="(max-width: 640px)"
            />
            <img src={src} alt={title} />
          </picture>
          <div className="picTitle only-mobile">{title}</div>
        </div>
        <div className={`textContainer`}>
          <div className="font-28 semi-bold t-color only-desktop">{title}</div>
          <div className="font-20 mt-24 description">{description}</div>
        </div>
      </div>
    ))}
  </div>
)
const Solution = ({ title, static_data }) => (
  <div className="edcs-solution">
    <Drop color="#fff" bgColor="#f5f9ff" style={{ top: 0 }} />
    <AnimationWrap>
      <div className="edcs-solution-container full-bg">
        <div className="font-36 semi-bold t-color mobile-padding">{title}</div>
        <LeftRightLayout static_data={static_data} className="picRight" />
      </div>
    </AnimationWrap>
  </div>
)
const Wow = () => (
  <div className="edcs-wow">
    <div className="edcs-wow-bg full-bg">
      <AnimationWrap>
        <div className="font-36 semi-bold t-color mobile-padding">
          {static_data.wow.title}
        </div>
        <LeftRightLayout
          static_data={static_data.wow.content.map((i, idx) => ({
            ...i,
            src: `${staticPath}/wow-${idx + 1}.png`,
          }))}
        />
      </AnimationWrap>
    </div>

    {/* <Support /> */}
    <ProductSupport
      source="edcs"
      bgColor="linear-gradient(149deg,rgba(223,249,255,1) 0%,rgba(175,233,248,1) 53%,rgba(133,229,249,1) 100%)"
      bgPic={`${staticPath}/support-bg.svg`}
      woman={`${staticPath}/support-woman.png`}
    />
  </div>
)
export default function Home({ data }) {
  const { allStrapiPosts, strapiTestimonials } = data
  console.log(allStrapiPosts.edges.map(({ node }) => node))
  return (
    <div className="edcs">
      <SEO {...static_data.seo} featuredImage="/featuredImage/edcs.jpg" />
      <SolutionTopBanner
        {...static_data.topBanner}
        bgColor="linear-gradient(162deg,rgba(247,254,255,1) 0%,rgba(224,249,255,1) 100%)"
        bgPic={`${staticPath}/topBanner-bg.svg`}
        animatedIcon={`${staticPath}/animatedIcon.svg`}
        iconBg={`${staticPath}/iconBg.svg`}
        backgroundAnimated={true}
        source="edcs"
        bannerPic={{
          src: `${staticPath}/topBanner-bg-m.svg`,
          style: {
            backgroundPosition: 'top center',
          },
        }}
      />
      <ProductNav
        name={static_data.edcs}
        menus={static_data.navigation}
        minWidth={1100}
      />
      <a id="overview" />
      <Feature />
      <Solution
        title={static_data.solution.title}
        static_data={static_data.solution.content.map((i, idx) => ({
          ...i,
          src: `${staticPath}/solution-${idx + 1}.png`,
        }))}
      />
      <Partnership />
      <a id="locations" />
      <MapContainer
        title={static_data.map.title}
        defaultSelected={[url_data.edcs.name]}
        description={static_data.map.description}
      />
      <Wow />
      <Feedback {...strapiTestimonials} />

      <BottomBanner {...static_data.bottomBanner} />
      <Action
        picM={`${staticPath}/action-m.png`}
        pic={`${staticPath}/action.png`}
        title={static_data.action.title}
        description={static_data.action.description}
        buttonText={static_data.action.buttonText}
        onClick={() => {
          window.open('/customer-story/talkcloud')
        }}
      />
      <PartSolution
        title={static_data.part_solution.title}
        description={static_data.part_solution.description}
        style={{ background: '#F5F9FF' }}
      />
      <a id="learnMore" />
      <ProductBlog
        title={static_data.blog.title}
        // bgColor="rgb(237,252,255)"
        blogs={allStrapiPosts.edges.map(({ node }) => node)}
      />
      <Footer />
    </div>
  )
}
export const query = graphql`
  {
    allStrapiPosts(
      filter: {
        categories: { elemMatch: { name: { eq: "Edge Data Center Services" } } }
        published: { eq: true }
        author: {}
      }
    ) {
      edges {
        node {
          slug
          thumbnail {
            localFile {
              publicURL
            }
          }
          wp_date
          created_at
          content
          categories {
            name
          }
          title
          author {
            id
            name
          }
          excerpt
        }
      }
    }
    strapiTestimonials(inPage: { eq: "edcs" }) {
      customerTitle
      customerName
      content
      companyPreview {
        localFile {
          publicURL
        }
      }
      logo {
        localFile {
          publicURL
        }
      }
      companyName
    }
  }
`
