import React, { useEffect } from 'react'
import SEO from '../../components/SEO'
import { graphql, Link } from 'gatsby'
import Footer from '../../components/footer'
import { ProductNav } from '../../components/header/Header'
import '../../styles/bmc.less'
import Map from '../../components/map'
import { ConsultationPopButton } from '../../components/form-biz'
import Drop from '../../components/drop'
import { SolutionTopBanner } from '../../components/block'
import { Action } from './edge-data-center-services'
import { lang } from '../../utils'
import Form from '../../components/form'
import Button from '../../components/ui/button'
import { LpPopButton } from '../../components/form-biz'
const BMCTable = require(`../../assets/bmc-server-${lang}.svg`)
const BMCLPTable = require(`../../assets/bmc-server-lp-en.svg`)
const BMCLPTableL = require('../../assets/bmc-server-lp-en-l.svg')
const BMCLPTableM = require('../../assets/bmc-server-lp-en-m.svg')
const BMCLPTableS = require('../../assets/bmc-server-lp-en-s.svg')
const keyToTable = {
  l: BMCLPTableL,
  s: BMCLPTableS,
  m: BMCLPTableM,
}
const loadText = require('src/utils').loadText
import { Filter } from '../../pages-en/lp/baremetal'
const url_data = loadText('url')
const static_data = loadText('bmc')
import { ProductSupport, ProductBlog } from '../../components/block'
import AnimationWrap from '../../components/block/AnimationWrap'
const jumpToSignin = (utm_id?: string) => {
  window.open(
    `https://console.zenlayer.com/auth/signup/input?utm_source=google&utm_medium=cpc&utm_campaign=BMC&utm_id=${
      utm_id || 'BMC'
    }&referral=${encodeURIComponent(`${location.origin}/${location.pathname}`)}`
  )
}
const navigate = (url) => window.open(url)
import newProductMap from '../../utils/newProductsMap'
const KeyFeatures = ({ isLp }) => {
  return (
    <div className="bmc-keyFeatures">
      <div>
        <div className="bmc-keyFeatures-block1">
          <AnimationWrap>
            <div className="bmc-t1">{static_data.feature[0].title}</div>
            <div
              className="font-20 text-center mt-24 block-middle"
              style={{ maxWidth: '1016px' }}
            >
              {static_data.feature[0].description.map((d) => (
                <div>{d}</div>
              ))}
            </div>
          </AnimationWrap>
        </div>
        <div className="bmc-keyFeatures-block2-container">
          <Drop color="#fff" style={{}} />
          <AnimationWrap>
            <div className="bmc-keyFeatures-block2 full-bg">
              <div className="bmc-keyFeatures-block2_left">
                <div className="imgContainer">
                  <img
                    src="/products/bmc/bmc-b2-icon.png"
                    alt="console"
                    className="bmc-keyFeatures-block2-console"
                  ></img>
                </div>
                <img
                  src="/products/bmc/bmc-b2-man.png"
                  alt="human"
                  className="bmc-keyFeatures-block2-human"
                />
              </div>
              <div className="bmc-keyFeatures-block2_right ">
                <div className="bmc-t1 title">
                  {static_data.feature[1].title}
                </div>
                <div className="font-20 mt-24 content">
                  {static_data.feature[1].description[0]}
                </div>
                {isLp && (
                  <div className="mt-32" style={{ textAlign: 'center' }}>
                    <Button
                      width="100%"
                      size="l"
                      onClick={() => jumpToSignin()}
                    >
                      Try today
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </AnimationWrap>
        </div>
      </div>

      <HybridClouds />

      <ProductSupport
        source="bmc"
        bgPic="/products/bmc/bmc-b4-bg.svg"
        woman="/products/bmc/bmc-b4-woman.png"
        bgColor="linear-gradient(97deg, rgba(246, 247, 254, 1) 0%,rgba(231, 230, 255, 1) 100%)"
      />
    </div>
  )
}
export const IconGroups = () => {
  return (
    <div className="mt-40 bmc-keyFeatures-block3-iconWrapper">
      <div>
        <div className="title">{static_data.feature[2].cloudLogo}</div>
        <div className="icons">
          {(lang === 'en'
            ? ['AWS', 'Azure', 'Google', 'Alibaba', 'UCloud', 'Tencent']
            : ['AWS', 'Azure', 'Alibaba', 'UCloud', 'Tencent']
          ).map((key) => (
            <div key={key} className="bmc-keyFeatures-block3-icon">
              <img src={`/products/bmc/${key}.svg`} alt={key}></img>
              <div className="font-16 mt-8 name">{key}</div>
            </div>
          ))}
        </div>
      </div>
      <div>
        <div className="title">{static_data.feature[2].interconnectLogo}</div>

        <div className="icons">
          {(lang === 'en' ? ['Equinix', 'Megaport', 'Coresite'] : []).map(
            (key) => (
              <div key={key} className="bmc-keyFeatures-block3-icon">
                <img src={`/products/bmc/${key}.svg`} alt={key}></img>
                <div className="font-16 mt-8">{key}</div>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  )
}
const HybridClouds = () => (
  <div className="bmc-keyFeatures-block3-outterBg">
    <div className="bmc-keyFeatures-block3-container">
      <div className="bmc-keyFeatures-block3 full-bg">
        <AnimationWrap>
          <div className="bmc-t1 mobile-padding" style={{ color: '#fff' }}>
            {static_data.feature[2].title}
          </div>
          <div
            className="font-20 text-center mobile-padding mt-24 block-middle"
            style={{ maxWidth: '941px' }}
          >
            {static_data.feature[2].description[0]}
          </div>
          <IconGroups />
        </AnimationWrap>
      </div>
    </div>
  </div>
)
export const MapContainer = ({
  title,
  description,
  defaultSelected,
  isLP,
}: any) => {
  return (
    <div className="bmc-map">
      <AnimationWrap>
        <div className="bmc-t1 mobile-padding">{title}</div>
        <div
          className="font-20 block-middle text-center mobile-padding mt-24 block-middle description"
          style={{ maxWidth: '981px' }}
        >
          {description}
        </div>
      </AnimationWrap>
      <div className="mt-56">
        {isLP ? (
          <div className="bmc-promote">
            <Filter />
          </div>
        ) : (
          <Map defaultSelected={defaultSelected} />
        )}
      </div>
    </div>
  )
}
const Reason = () => {
  return (
    <div className="bmc-reason">
      <AnimationWrap>
        <div className="font-36 text-center semi-bold t-color mobile-padding">
          {static_data.reason.title}
        </div>
        <div
          className="font-20 text-center mobile-padding mt-24 block-middle"
          style={{ maxWidth: '981px' }}
        >
          {static_data.reason.description}
        </div>
        <div className="bmc-reason-feature">
          <picture>
            <source
              srcSet={`/products/bmc/solution-${lang}-m.png 640w `}
              media="(max-width: 640px)"
            />
            <img
              src={`/products/bmc/solution-${lang}.png`}
              alt="bmc-solution"
              className="mobile-padding"
            />
          </picture>
        </div>
        <div className="mobile-padding">
          <div className="bmc-reason-badge full-bg font-20 mobile-padding">
            <div>{static_data.reason.bannerText}</div>
          </div>
        </div>
      </AnimationWrap>
    </div>
  )
}
export const Feedback = (data) => {
  const {
    companyPreview,
    companyName,
    logo,
    customerName,
    content,
    customerTitle,
  } = data
  // console.log(data)

  return data.logo ? (
    <AnimationWrap>
      <div className="bmc-feedback">
        <div className="font-36 text-center semi-bold t-color mobile-padding">
          {static_data.feedback.title}
        </div>
        <div className="bmc-feedback-quoteContainer">
          <div className="bmc-feedback-quote" style={{ background: '#f3f5f9' }}>
            <div className="font-20 semi-bold t-color companyName">
              {companyName}
            </div>
            <div className="bmc-feedback-quoteContent font-16 t-color">
              {content}
            </div>
            <div className="bmc-feedback-quote-author">
              <div className="font-16 semi-bold t-color">{customerName}</div>
              <div className="bmc-feedback-quote-authorTitle mt-8">
                {customerTitle}
              </div>
            </div>
            <img
              src={logo.localFile.publicURL}
              alt={companyName}
              className="bmc-feedback-quote-companyLogo"
            />
          </div>
          <img
            src={(companyPreview.localFile || {}).publicURL}
            alt={`${companyName}-preview`}
            className="bmc-feedback-companyIcon"
          />
        </div>
      </div>
    </AnimationWrap>
  ) : null
}
const Server = ({ isLP = false }: { isLP?: boolean }) => {
  return (
    <div className="bmc-server">
      <AnimationWrap>
        <div className="font-36 semi-bold t-color">
          {static_data.server.title}
        </div>
        {isLP && (
          <div className="xeno">
            Powered by Intel® Xeon® processors
            <img src="/products/bmc/xeno.png" alt="xeno" />
          </div>
        )}
        <div
          style={{ maxWidth: '1224px' }}
          className="block-middle mt-56 only-desktop"
        >
          {isLP ? <BMCLPTable /> : <BMCTable />}
        </div>
        <div className="only-mobile bmc-server-detail">
          {['s', 'm', 'l'].map((size) => {
            const Comp = keyToTable[size]
            return isLP ? (
              <div
                style={{
                  display:"flex",
                  margin: '48px auto 0 auto',
                  borderRadius: '8px',
                  boxShadow: '0px 2px 4px 0px rgba(8, 30, 94, 0.15)',
                  // height: '376px',
                  overflow: 'hidden',
                  width: '544px',
                }}
              >
                <Comp />
              </div>
            ) : (
              <img
                src={`/products/bmc/server-${lang}-${size}.png`}
                alt=""
                style={{ width: '554px' }}
              />
            )
          })}
        </div>
        <div className="mt-64 btn">
          {isLP ? (
            <div>
              <LpPopButton
                source="Bare Metal Cloud Landing Page"
                // buttonWidth={'100%'}
                buttonClassName="bmc-server-button"
              >
                Get a quote for the best pricing today
              </LpPopButton>
            </div>
          ) : (
            // <ConsultationPopButton buttonWidth={600} source="bmc">
            //   Get a quote for the best pricing today
            // </ConsultationPopButton>
            <Button
              width={476}
              type="primary"
              size="l"
              onClick={() => {
                jumpToSignin()
              }}
            >
              {static_data.server.buttonText}
              {/* xxxx */}
            </Button>
          )}
        </div>
      </AnimationWrap>
    </div>
  )
}

const Os = () => {
  return (
    <div className="bmc-os">
      <div className="bmc-os-imgContainer full-bg">
        <AnimationWrap>
          <div className="bmc-osContent page-middle">
            <div className="font-36 semi-bold mobile-padding">
              {static_data.os.title}
            </div>
            <div
              className="font-20 text-center mt-24 block-middle mobile-padding"
              style={{ maxWidth: '600px' }}
            >
              {static_data.os.description}
            </div>
            <div className="bmc-os-itemContainer">
              {static_data.os.type.map((d, idx) => (
                <div key={d} className="bmc-os-item">
                  <img src={`/products/bmc/os${idx + 1}.svg`} alt="d" />
                  <div className="font-20 mt-8">{d}</div>
                </div>
              ))}
            </div>
          </div>
        </AnimationWrap>
      </div>
      <Drop color="rgb(147,151,237)" />
    </div>
  )
}
export const Solution = ({
  title,
  description,
  style = {},
  hideMap = {},
}: {
  title: string
  description: string
  hideMap?: { [key: string]: boolean }
  style?: React.CSSProperties
}) => {
  return (
    <div className="bmc-solution " style={style}>
      <div className="page-middle">
        <AnimationWrap>
          <h2 className="bmc-sectionTitle mobile-padding">{title}</h2>
          <div className="font-20 text-center mt-24 mobile-padding description block-middle">
            {description}
          </div>
          <div className="bmc-solution-itemContainer">
            {static_data.solution.content
              .filter(({ _key }) => !hideMap[_key])
              .map(({ name, description, _key }, idx) => (
                <div
                  className="bmc-solution-item"
                  onClick={() => navigate(url_data[_key].link)}
                >
                  <img
                    src={`/header/${_key}.svg`}
                    alt={name}
                    style={{ width: '110px' }}
                  />
                  <h3 className="font-16 title semi-bold mt-16">{name}</h3>
                  <div className="mt-8 description">{description}</div>
                  {newProductMap[_key] && (
                    <img
                      src={'/new-badge.svg'}
                      className="new-badge1"
                      alt="new-product"
                    />
                  )}
                </div>
              ))}
          </div>
        </AnimationWrap>
      </div>
    </div>
  )
}
const LPHeader = () => (
  <div className="lp-bmc-header-wrapper">
    <div className="lp-bmc-header">
      <div className="lp-bmc-header-bg">
        <div className="lp-bmc-header-content-wrapper">
          <div className="lp-bmc-header-top">
            <div className="left">
              <div>
                <Link to="/">
                  <img src="/logo-blue.svg" alt="" />
                </Link>
              </div>
              <div className="description">
                {static_data.lpTopBanner.topDescription}
              </div>
            </div>
          </div>
          <div className="lp-bmc-header-content">
            <div className="left">
              <div className="title">{static_data.lpTopBanner.title}</div>
              <div className="subtitle">{static_data.lpTopBanner.subtitle}</div>
              <div className="description">
                {static_data.lpTopBanner.description}
              </div>
              <div className="mt-32">
                <ConsultationPopButton source="bmc-lp">
                  Contact us
                </ConsultationPopButton>
                <Button
                  size="l"
                  className="white btn-2"
                  onClick={() => {
                    jumpToSignin()
                  }}
                >
                  {static_data.lpTopBanner.buttonText}
                </Button>
              </div>
            </div>
            <div className="right">
              <img src="/products/bmc/lp-topBanner-pic.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)
const LpB1 = () => {
  const data = static_data.lpB1
  return (
    <div className="lp-bmc-b1">
      <div className="title">{data.title}</div>
      <div className="content">
        {data.content.map(({ title, content }, idx) => (
          <div className="item">
            <div className="left">
              <img src={`/products/bmc/lp-b1-${idx + 1}.svg`} alt={title} />
            </div>
            <div className="right">
              <div className="title">{title}</div>
              <div className="content">{content}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
export default function ({ data }) {
  const { allStrapiPosts, strapiTestimonials } = data
  return (
    <div className="bmc">
      <SEO {...static_data.seo} featuredImage="/featuredImage/bmc.jpg" />
      <>
        <SolutionTopBanner
          {...static_data.topBanner}
          backgroundAnimated={true}
          bgColor="linear-gradient(180deg,rgba(246, 247, 254, 1) 0%,rgba(231, 230, 255, 1) 100%)"
          bgPic="/products/bmc/topBanner-bg.svg"
          animatedIcon="/products/bmc/animatedIcon.svg"
          iconBg="/products/bmc/iconBg.svg"
          source="bmc"
          height={842}
          bannerPic={{
            src: `/products/bmc/topBanner-bg-m.svg`,
            style: {
              backgroundPosition: 'top center',
            },
          }}
        />
        <ProductNav
          name={static_data.bmc}
          menus={static_data.navigation}
          minWidth={1100}
        />
      </>
      <a id="overview" />
      <KeyFeatures isLp={false} />
      <a id="locations" />
      <MapContainer
        title={static_data.map.title}
        description={static_data.map.description}
        defaultSelected={[url_data.bmc.name]}
      />
      <a id="hybridClouds" />
      <Reason />
      <a id="testimonials" />
      <Feedback {...strapiTestimonials} />
      <Action
        pic={`/products/bmc/action.png`}
        picM={`/products/bmc/action-m.png`}
        title={static_data.action.title}
        description={static_data.action.description}
        buttonText={static_data.action.buttonText}
        onClick={() => {
          navigate('/customer-story/gaming')
        }}
      />
      <a id="configurations" />
      <Server />
      <Os />
      <Solution
        title={static_data.solution.title}
        description={static_data.solution.description}
      />
      <a id="learnMore" />
      <ProductBlog
        title={static_data.blog.title}
        blogs={allStrapiPosts.edges.map(({ node }) => node)}
      />
      <Footer />
    </div>
  )
}
export function LP({ data }) {
  const { allStrapiPosts, strapiTestimonials } = data
  useEffect(() => {
    const bmcServer = document.querySelector('.bmc-server')
    const handler = (e: any) => {
      let closest = e.target.closest('.svg-btn')
      if (closest) {
        jumpToSignin(closest.dataset.utm_id)
      }
    }
    if (bmcServer) {
      bmcServer.addEventListener('click', handler)
    }
    return () => {
      if (bmcServer) {
        bmcServer.removeEventListener('click', handler)
      }
    }
  }, [])
  return (
    <div className="bmc lp">
      <SEO {...static_data.seo} featuredImage="/featuredImage/bmc.jpg" />
      <LPHeader />
      <LpB1 />
      {/* <div className="lp-bmc-form">
        <div className="lp-bmc-form-bg">
          <div className="lp-bmc-form-content">
            <div className="lp-bmc-form-title">
              Schedule a free consultation
            </div>
            <Drop
              color="rgb(246,235,255)"
              className="only-desktop"
              bgColor="#fff"
              style={{ top: 0 }}
            />
            <Form
              type="lp"
              btnText="Submit"
              onSuccessWithoutModal={() => {
                navigate('/lp/thanks')
              }}
              onSubmit={async (data) => {
                await sendData({
                  data,
                  url: 'https://go.zenlayer.com/l/879902/2021-04-19/3v5dj',
                  source: 'Bare Metal Cloud Landing Page',
                })
              }}
            />
          </div>
        </div>
      </div> */}
      <Server isLP={true} />
      <a id="locations" />
      <MapContainer
        title={static_data.map.title}
        description={static_data.map.description}
        defaultSelected={[url_data.bmc.name]}
        isLP={true}
      />
      <a id="overview" />
      <KeyFeatures isLp={true} />
      <a id="hybridClouds" />
      <Reason />
      <a id="testimonials" />
      <Feedback {...strapiTestimonials} />
      <Action
        pic={`/products/bmc/action.png`}
        picM={`/products/bmc/action-m.png`}
        title={static_data.action.title}
        description={static_data.action.description}
        buttonText={static_data.action.buttonText}
        onClick={() => {
          navigate('/customer-story/gaming')
        }}
      />
      <a id="configurations" />
      <Os />
      <Solution
        title={static_data.solution.title}
        description={static_data.solution.description}
      />
      <a id="learnMore" />
      <ProductBlog
        title={static_data.blog.title}
        blogs={allStrapiPosts.edges.map(({ node }) => node)}
      />
      <Footer />
    </div>
  )
}

export const query = graphql`
  {
    allStrapiPosts(
      filter: {
        categories: { elemMatch: { name: { eq: "Bare Metal Cloud" } } }
        author: {}
        published: { eq: true }
      }
    ) {
      edges {
        node {
          slug
          categories {
            name
          }
          wp_date
          created_at
          content
          title
          thumbnail {
            localFile {
              publicURL
            }
          }
          author {
            slug
            name
          }
          excerpt
        }
      }
    }
    strapiTestimonials(inPage: { eq: "bmc" }) {
      customerTitle
      customerName
      content
      companyPreview {
        localFile {
          publicURL
        }
      }
      logo {
        localFile {
          publicURL
        }
      }
      companyName
    }
  }
`
